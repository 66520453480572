/* eslint-disable no-useless-escape */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

import Fade from 'react-reveal/Fade';
import * as emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form from 'elements/Form';
import Button from 'elements/Button';
import contactus from 'assets/images/contactus.png';

export default function DiscussForm(props) {
  const { data, resetForm } = props;

  const submitEmail = () => {
    const {
      name, company, email, phone, projectIdea,
    } = data;

    const templateParams = {
      from_name: `${name} - ${company} ( ${phone} - ${email} )`,
      to_name: 'ServingSol',
      message: projectIdea,
    };

    if (
      name !== ''
            && company !== ''
            && email !== ''
            && phone !== ''
            && projectIdea !== ''
    ) {
      emailjs.send(
        'service_info',
        'template_contact',
        templateParams,
        'DCHecUZe1oXobv7rG',
      )
        .then(() => {
          toast.success('Success! we\'\ll get back to you soon. Thank you!');
          resetForm();
        }, (error) => {
          toast.error(error);
        });
    } else {
      toast.error('Please fill out the blank form.');
    }
  };

  return (
    <section className="hero flex flex-col container mx-auto mt-20 justify-center hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <div className="w-full flex justify-center">
            <div className="w-full sm:w-1/2 sm:pr-12">
              <img src={contactus} alt="Hero" />
            </div>
          </div>
          <h1 className="text-5xl text-theme-dark-Blue text-center font-bold">Get in Touch</h1>

          <p className="font-light text-lg text-black text-center mb-12 dark:text-white">          
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Please fill out the form below to discuss your project and we ll get back to you in less than 24 hours.
          </p>
        </div>
        <div className="flex flex-col ">
          <div className="flex flex-col sm:flex-row mx-auto" style={{ marginTop: '100px' }}>
            <Form
              id="name"
              name="name"
              type="text"
              value={data.name}
              placeholder="Your name"
              className=""
              onChange={props.onChange}
            />
            <Form
              id="company"
              name="company"
              type="text"
              value={data.company}
              placeholder="Your company"
              className=""
              onChange={props.onChange}
            />
          </div>

          <div className="flex flex-col sm:flex-row mx-auto">
            <Form
              id="email"
              name="email"
              type="email"
              value={data.email}
              placeholder="Your email address"
              className=""
              onChange={props.onChange}
            />
            <Form
              id="phone"
              name="phone"
              type="tel"
              value={data.phone}
              placeholder="Your contact number"
              className=""
              onChange={props.onChange}
            />
          </div>

          <div className="mx-auto">
            <Form
              id="projectIdea"
              name="projectIdea"
              type="textarea"
              value={data.projectIdea}
              placeholder="Explain about your project idea"
              className=""
              onChange={props.onChange}
            />
          </div>

          <Button
            className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-dark-Blue text-white rounded-full  hover:bg-theme-Blue transition duration-200 focus:outline-none"
            type="button"
            onClick={submitEmail}
          >
            Submit
          </Button>
        </div>
      </Fade>

      <ToastContainer />

    </section>
  );
}
