import React from 'react';
import { Fade } from 'react-reveal';
import { MailIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/react/outline';

const ContactInfo = () => (
  <Fade bottom>
    <div className="grid grid-rows-3 px-10 gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16 mb-10">
      <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-md border border-theme-Blue dark:bg-theme-black dark:border-theme-Blue">
        <MailIcon className="h-10 w-10 text-theme-Blue" />
        <h2 className="text-2xl font-semibold mt-2 text-inside-theme-black">E-mail</h2>
        <p className="font-light text-xl mt-1 dark:text-white">servingsol1@gmail.com</p>
      </div>

      <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-md border border-theme-Blue dark:bg-theme-black dark:border-theme-Blue">
        <PhoneIcon className="h-10 w-10 text-theme-Blue" />
        <h2 className=" text-2xl font-semibold mt-2 text-inside-theme-black">Phone</h2>
        <p className=" font-light text-xl mt-1 dark:text-white">+92322-9664668</p>
      </div>

      <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-md border border-theme-Blue dark:bg-theme-black dark:border-theme-Blue">
        <LocationMarkerIcon className="h-10 w-10 text-theme-Blue" />
        <h2 className=" font-light text-2xl font-semibold mt-2 text-inside-theme-black">Address</h2>
        <p className="font-light text-xl mt-1 dark:text-white text-center">552-C Peoples Colony No 2</p>
      </div>
    </div>
  </Fade>

);

export default ContactInfo;
