/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';

import ThemeContext from 'elements/ThemeContext';

import Fade from 'react-reveal/Fade';

import Button from 'elements/Button';

import BuildWebsite from 'assets/images/Cover.png';

export default function Hero() {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={`${darkMode && 'dark'}`}>
      <section className="hero dark:bg-theme-black">
        <Fade bottom>
          <div className="w-full lg:w-1/2 xl:pl-12 sm:pr-2 mt-8">
            <h2 className="text-2xl sm:text-6xl text-theme-dark-Blue font-bold leading-tight mb-5">
              Where solution
              <span className=""> meets Excellence</span>
            </h2>
            <p className="font-light text-xl text-theme-light-Blue leading-relaxed mb-16 dark:text-white">
              Welcome to ServingSol! We ensure to make your  journey interesting and memorable.
            </p>
            <Button href="/project" type="link" className="flex w-71 h-18 items-center px-14 py-5 text-white text-xl bg-theme-dark-Blue rounded-lg shadow-2xl hover:bg-dark-theme-dark-Blue transition duration-200">
              See Our Work
              <svg className="ml-2 w-7 h-7 text-white animate-bounce-x" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </Button>
          </div>
        </Fade>

        <Fade bottom>
          <div className="flex pt-5 w-full justify-center items-center order-first md:w-full lg:order-last lg:w-1/2">
            <img className="" src={BuildWebsite} alt="Build Website" />
          </div>
        </Fade>
      </section>
    </div>
  );
}
