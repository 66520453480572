/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';
import ThemeContext from 'elements/ThemeContext';

import BrandIcon from 'parts/BrandIcon';
import Button from 'elements/Button';

export default function Footer() {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={`${darkMode && 'dark'}`}>
      <div className="bg-gray-150 border-t border-gray-200 pb-6 dark:bg-theme-black">
        <div className="container flex-col mx-auto ">
          <div className="flex flex-col sm:flex-row mt-8 justify-center">
            <div className="w-1/3 flex-col ml-16 mr-8">
              <BrandIcon />
              <p className="w-full text-lg text-theme-light-Blue font-light dark:text-white">
                Where Solution
                {' '}
                <br />
                Meets Excellence
              </p>
            </div>
            <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
              <h1 className="text-lg text-theme-blue pt-4 pb-2 dark:text-white">
                Office
              </h1>
              <p className="text-lg text-gray-400 font-light">
                Phone: +92322-9664668
              </p>
              <p className="text-lg text-gray-400 font-light">
                E-mail: servingsol1@gmail.com
              </p>
              <p className="text-lg text-gray-400 font-light">
                Adress: 552-C Peoples Colony No 2 Faisalabad

              </p>
            </div>
            <div className="w-1/3 ml-16 sm:ml-0 mt-0">
              <h1 className="text-lg text-theme-blue pt-4 pb-2 dark:text-white">
                Social
              </h1>
              <Button href="https://www.linkedin.com/company/servingsol/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
                LinkedIn
              </Button>
              <Button href="https://www.facebook.com/p/Serving-Sol-100094742400552/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
                Facebook
              </Button>
              <Button href="https://www.instagram.com/serving_sol/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
                instagram
              </Button>
            </div>
          </div>
          <div className="flex-col text-center mt-7">
            <p className="text-lg text-gray-400 font-light">
              Copyright 2024 - All rights reserved - ServingSol
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
