/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Button from 'elements/Button';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/pages/LandingPage.js"
    >
      <p className="text-theme-dark-Blue text-4xl">
        Serving
        <span className="text-theme-Blue">Sol</span>
      </p>
    </Button>
  );
}
