/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // Fix BrowserRouter alias
import ThemeContext from 'elements/ThemeContext';
import LandingPage from 'pages/LandingPage';
import ProjectPage from 'pages/ProjectPage';
import ProjectDetailPage from 'pages/ProjectDetailPage';
import TeamPage from 'pages/TeamPage';
import DiscussProjectPage from 'pages/DiscussProjectPage';
import NotFoundPage from 'pages/NotFoundPage';
import Dark from 'parts/Dark';

import 'assets/css/styles.css';

function App() {
  const { darkMode } = useContext(ThemeContext);
  return (
    <div className={`${darkMode && 'dark bg-theme-black'}`}>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/project" component={ProjectPage} />
          <Route exact path="/project/:id" component={ProjectDetailPage} />
          <Route exact path="/team" component={TeamPage} />
          <Route exact path="/discuss-project" component={DiscussProjectPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
      <Dark />
    </div>
  );
}

export default App;
