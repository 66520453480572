/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';
import ThemeContext from 'elements/ThemeContext';

import Header from 'parts/Header';
import HeroTeam from 'parts/HeroTeam';
import AllTeam from 'parts/AllTeam';
import Footer from 'parts/Footer';
import Dark from 'parts/Dark';
import Data from 'json/landingPage.json';

const TeamPage = (props) => {
  const { darkMode } = useContext(ThemeContext);
  return (
    <>
      <div className={darkMode ? 'dark' : ''}>
        <div className={darkMode ? 'dark:bg-theme-black' : ''}>
          <Header {...props} />
          <HeroTeam {...props} />
          <AllTeam data={Data.team} />
          <Footer />
          <Dark />
        </div>
      </div>
    </>
  );
};

export default TeamPage;
