import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

// Create a new context
const ThemeContext = createContext();

// Create a provider component
export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return React.createElement(
    ThemeContext.Provider,
    { value: { darkMode, toggleDarkMode } },
    children,
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContext;
