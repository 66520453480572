/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';
import ThemeContext from 'elements/ThemeContext';

import Header from 'parts/Header';
import HeroPortfolio from 'parts/HeroPortfolio';
import Discuss from 'parts/Discuss';
import Footer from 'parts/Footer';
// import AllPortfolio from 'parts/AllPortfolio';
import Dark from 'parts/Dark';
import Portfolio from 'parts/Portfolio';
import Data from 'json/landingPage.json';

const ProjectPage = (props) => {
  const { darkMode } = useContext(ThemeContext);
  return (
    <>
      <div className={darkMode ? 'dark' : ''}>
        <div className={darkMode ? 'dark:bg-theme-black' : ''}>
          <Header {...props} />
          <HeroPortfolio {...props} />
          <Portfolio data={Data.portfolio} />
          <Discuss {...props} />
          <Footer />
          <Dark />
        </div>
      </div>
    </>
  );
};

export default ProjectPage;
